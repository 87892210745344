<template>
  <el-dialog title="Clienti" :visible.sync="showPopup" class="my-dialog-class" append-to-body>
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="6">
          <el-form-item prop="Nume" label="Nume">
            <el-input class="full-width" v-model="selectedObject.Nume" size="mini"/>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="CodFiscal" label="Cod fiscal">
            <div class="input-group full-width">
              <el-input
                @change="handle_cui()"
                v-model="selectedObject.CodFiscal" size="mini"
              />
              <div class="btn-a input-group-append">
                <el-button
                  plain size="mini"
                  type="success"
                  icon="el-icon-cloudy"
                  @click="interogare_cui()"
                  class="success"
                ></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="RegCom" label="Nr registrul comertului">
            <el-input class="full-width" v-model="selectedObject.RegCom" size="mini"/>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="Cont" label="Cont">
            <el-input class="full-width" v-model="selectedObject.Cont" size="mini"/>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="Banca" label="Banca">
            <el-input class="full-width" v-model="selectedObject.Banca" size="mini"/>
          </el-form-item>
        </el-col>

        <el-col :md="6">
          <el-form-item prop="TermenPlata" label="Termen plata (zile)">
            <el-input-number class="full-width" v-model="selectedObject.TermenPlata" controls-position="right" :precision="0"
                        :min="0" size="mini"
                        :step="1"/>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="Discount" label="Discount  %">
            <el-input-number class="full-width" v-model="selectedObject.Discount" :precision="0"
                        controls-position="right" size="mini"
                        :min="0"
                        :step="1"/>
          </el-form-item>
        </el-col>
        </el-row>
        <el-row :gutter="15">
        <el-col :md="6">
          <el-form-item prop="IdCategorieClient" label="Categorie">
            <div class="input-group full-width">
              <el-select v-model="selectedObject.IdCategorieClient" filterable size="mini">
                <el-option
                  v-for="item in Info.categorii"
                  :key="'categorii' + item.Id"
                  :label="item.Denumire"
                  :value="item.Id"
                ></el-option>
              </el-select>
              <div class="btn-a input-group-append">
                <el-button
                  plain size="mini"
                  type="success"
                  icon="el-icon-plus"
                  @click="show_add_new_category()"
                  class="success"
                ></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>

        <el-col :md="6">
          <!-- <el-form-item label="Sector? TBD">
            <el-input
              disabled
              placeholder="TODO: sa fie select"
              class="full-width"
              v-model="selectedObject.Sector"
            />
          </el-form-item> -->

          <el-form-item label="Judet" prop="IdJudet">
            <el-select
            class="full-width"
              @change="get_localitate(selectedObject.IdJudet)"
              style="width: 100%"
              v-model="selectedObject.IdJudet"
              placeholder="Alege judetul"
              filterable size="mini"
            >
              <el-option
                v-for="item in Info.judete"
                :key="'judete' + item.Id"
                :label="item.Nume"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="6">
          <el-form-item prop="IdOras" label="Localitate">
            <el-select
            class="full-width"
              :disabled="selectedObject.IdJudet != '' ? false : true"
              style="width: 100%"
              v-model="selectedObject.IdOras"
              filterable size="mini"
            >
              <el-option
                v-for="item in Info.orase"
                :key="'orase' + item.Id"
                :label="item.Nume"
                :value="item.Id"
              ></el-option>
            </el-select>
            <!-- <div class="input-group-append">
              <el-button
                  plain
                  type="success"
                  icon="el-icon-plus"
                  @click="show_add_new_oras()"
                  class="success"
                ></el-button>
              </div> -->
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="Adresa" label="Adresa">
            <el-input placeholder="Adresa" type="textarea"  class="full-width" v-model="selectedObject.Adresa" size="mini"/>
          </el-form-item>
        </el-col>

      </el-row>
        <el-row :gutter="15">

        <el-col :md="6">
          <el-form-item prop="Livrare" label="Adresa livrare">
            <el-input placeholder="Adresa de livrare" type="textarea"  class="full-width" v-model="selectedObject.Livrare" size="mini"/>
          </el-form-item>
        </el-col>

        <el-col :md="6">
          <el-form-item label="Descriere" prop="Descriere">
            <el-input
              type="textarea"
               size="mini"
              placeholder="Descriere"
              v-model="selectedObject.Descriere"
            />
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="Gestiune implicita">
            <el-select :disabled="!selectedObject.Special" style="width: 100%" v-model="selectedObject.IdGestiuneSursaDefault" filterable size="mini" >
              <el-option label="Fara" value="-1" />
              <el-option v-for="item in Info.gestiuni" :key="item.Id + item.Nume" :label="item.Nume" :value="item.Id" />
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :md="6">
          <el-form-item label="Gestiune implicita avizare">
            <el-select :disabled="!selectedObject.Special" style="width: 100%" v-model="selectedObject.IdGestiuneAvizareDefault" filterable size="mini" >
              <el-option label="Fara" value="-1" />
              <el-option v-for="item in Info.gestiuniAvizare" :key="item.Id + item.Nume" :label="item.Nume" :value="item.Id" />
            </el-select>
          </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
        <el-col :md="6">
          <el-form-item>
            <el-switch
              v-model="selectedObject.Dezactivat"
              active-value="0"
              inactive-value="1"
              active-text="Activ"
              inactive-text="Dezactivat"
              active-color="#13ce66"
              inactive-color="#ff4949"
              size="mini"
             
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item >
            <el-switch
              v-model="selectedObject.PF"
              true-label="1"
              false-label="0"
              active-text="Persoana fizica" size="mini"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item>
            <el-switch
              v-model="selectedObject.Special"
              true-label="1"
              false-label="0"
              active-text="Special" size="mini"
            />
          </el-form-item>
        </el-col>        
        
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>

      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
    <Categorii-dialog ref="dlg-categorie-noua" @save="salvare_categorie()" />
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Categorii_dialog from "@/pages/clienti_categorii/Clienti_categorii_dialog.vue";

export default {
  name: "Clienti_dialog",
  extends: BasePage,
  components: {
    "Categorii-dialog": Categorii_dialog,
    // "Orase-dialog": Orase_dialog,
  },
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Nume: "",
        PF: "",
        CodFiscal: "",
        IdCategorieClient: "",
        IdOras: "",
        IdJudet: "",
        Sector: "",
        Adresa: "",
        Livrare: "",
        TermenPlata: "",
        Discount: 0,
        RegCom: "",
        Cont: "",
        Banca: "",
        Descriere: "",
        Dezactivat: "",
        Special: "",
        IdGestiuneSursaDefault: "-1",
        IdGestiuneAvizareDefault: "-1",
        cuivalid: false,
      },
      Info: {
        orase: [],
        gestiuni: [],
        gestiuniAvizare: [],
      },
      rules: {
        Nume: [{ required: true, message: "Campul este obligatoriu" }],
        Adresa: [{ required: true, message: "Campul este obligatoriu" }],
        IdCategorieClient: [
          { required: true, message: "Campul este obligatoriu" },
        ],
        IdOras: [{ required: true, message: "Campul este obligatoriu" }],
        IdJudet: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    get_localitate: async function (judet) {
      let args = { IdJudet: judet };
      let response = await this.post("clienti/get_localitate", args);
      this.Info.orase = response.localitati;
    },

    handle_cui: async function () {
      if (
        this.selectedObject.CodFiscal == "" ||
        this.selectedObject.CodFiscal.length < 0
      ) {
        this.selectedObject.cuivalid = false;
        // this.rules.Prenume.required = true;
      }
    },

    interogare_cui: async function () {
      let args = { CUI: this.selectedObject.CodFiscal };
      let response = await this.post("clienti/interogare_cui", args);
      // console.log(JSON.parse(response.CUI));
      let status = JSON.parse(response.CUI);
      console.log(status);
      // console.log(status);
      if (status.status === 200) {
        let data = JSON.parse(response.CUI).data;
        let nume = data.nume;
        let judet = this.scoate_diacritice(data.adresa_judet);
        let oras = this.scoate_diacritice(data.adresa_localitate);

        this.selectedObject.IdJudet = this.Info.judete.find(
          (j) => j.Nume.toLowerCase() === judet
        ).Id;
        await this.get_localitate(this.selectedObject.IdJudet);
        this.selectedObject.IdOras = this.Info.orase.find(
          (o) => oras.includes(o.Nume.toLowerCase())
        ).Id;

        this.selectedObject.Nume = nume;
        this.selectedObject.RegCom = data.cod_inmatriculare;
        this.selectedObject.Adresa = data.adresa;
        this.selectedObject.cuivalid = true;
        // this.rules.Prenume.required = false;
      } else {
        await this.$message.error("Cod fiscal invalid.");
      }
    },

    scoate_diacritice: function (word) {
      let data = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      data = data.toLowerCase();
      return String(data);
    },

    show_add_new_category() {
      this.$refs["dlg-categorie-noua"].show_me();
    },
    show_add_new_oras() {
      this.$refs["dlg-oras-nou"].show_me();
    },
    show_me: async function (id) {
      this.showPopup = true;
      this.selectedObject = {
        Nume: "",
        PF: "0",
        CodFiscal: "",
        IdCategorieClient: "",
        IdOras: "",
        IdJudet: "",
        Sector: "",
        Adresa: "",
        Livrare: "",
        TermenPlata: "",
        Discount: 0,
        RegCom: "",
        Cont: "",
        Banca: "",
        Descriere: "",
        Dezactivat: "0",
        IdGestiuneSursaDefault: "-1",
        IdGestiuneAvizareDefault: "-1",
        cuivalid: false,
      };
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("clienti/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
        await this.handleDezactivat("get");
        await this.handlePersoana("get");
        await this.handleSpecial("get");
        if (this.selectedObject.IdJudet != "") {
          this.get_localitate(this.selectedObject.IdJudet);
        }
        if (this.selectedObject.CodFiscal != "") {
          this.selectedObject.cuivalid = true;
          // this.rules.Prenume.required = false;
        }
      }
    },

    async get_info() {
      var response = await this.post("clienti/get_info_for_dialog");
      this.Info.categorii = response.categorii;
      this.Info.judete = response.judete;
      this.Info.gestiuni = response.gestiuni;
      this.Info.gestiuniAvizare = response.gestiuniAvizare;
    },
    async salvare_categorie() {
      this.get_info();
      var response = await this.post(
        "clienti_categorii/get_last_categorie",
        {}
      );
      this.selectedObject.IdCategorieClient = response.LastCategorieId;
      this.numeCategorie = response.NumeCategorie;
    },
    async salvare_oras() {
      this.get_info();
      var response = await this.post("orase/get_last_oras", {});
      this.selectedObject.IdOras = response.LastOrasId;
      this.numeOras = response.LastNumeOras;
    },

    handleDezactivat: async function (mode) {
      if (mode === "save") {
        if (this.selectedObject.Dezactivat) {
          this.selectedObject.Dezactivat = "1";
        } else {
          this.selectedObject.Dezactivat = "0";
        }
      } else if (mode === "get") {
        if (this.selectedObject.Dezactivat == "1") {
          this.selectedObject.Dezactivat = true;
        } else {
          this.selectedObject.Dezactivat = false;
        }
      }
    },    
    
    handleSpecial: async function (mode) {
      if (mode === "save") {
        if (this.selectedObject.Special) {
          this.selectedObject.Special = "1";
        } else {
          this.selectedObject.Special = "0";
        }
      } else if (mode === "get") {
        if (this.selectedObject.Special == "1") {
          this.selectedObject.Special = true;
        } else {
          this.selectedObject.Special = false;
        }
      }
    },

    handlePersoana: async function (mode) {
      if (mode === "save") {
        if (this.selectedObject.PF) {
          this.selectedObject.PF = "1";
        } else {
          this.selectedObject.PF = "0";
        }
      } else if (mode === "get") {
        if (this.selectedObject.PF == "1") {
          this.selectedObject.PF = true;
        } else {
          this.selectedObject.PF = false;
        }
      }
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          this.handleDezactivat("save");
          this.handleSpecial("save");
          this.handlePersoana("save");
          var result = await this.post("clienti/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save", result.obj);
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100% !important;
  .el-input {
    width: 85% !important;
  }
  .el-select {
    width: 85% !important;
  }

  .btn-a {
    margin: 0;
    padding: 0;
    width: 15% !important;
    .el-button {
      width: 100%;
      // display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// @media only screen and (max-width: 768px) {
//   .full-width .el-select {
//     width: 75% !important;
//   }
// }
</style>