<template>
    <base-page>

        <el-card style="margin: 5px 0px 5px 0px">
            <el-collapse accordion style="border: none">
                <el-collapse-item name="1" style="border: none">
                    <template slot="title">
                        <div slot="header" class="clearfix">
                            <titlu-pagina Titlu="Stoc marfă" @on_add_clicked="show_dialog()" :AdaugaVisible="false" />
                        </div>
                    </template>
                    <div class="filtre">
                        <el-form @submit="refresh_info()" label-position="top">

                            <el-row :gutter="20">

                                <el-col style="width: 20%">
                                    <el-form-item label="Gestiune" prop="Gestiune">
                                        <el-select class="full-width" v-model="Filters.IdGestiune" filterable size="small">
                                            <el-option v-for="item in Info.Gestiuni" :key="item.Id" :label="item.Nume" :value="item.Id" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col style="width: 20%">
                                    <el-form-item label="Categorie produs">
                                        <el-select class="full-width" v-model="Filters.Categorie" filterable size="small">
                                            <el-option label="Toate" value="-1" />
                                            <el-option v-for="item in Info.categorii" :key="item.Id" :label="item.Nume" :value="item.Id" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col style="width:10%">
                                    <el-form-item class="full-width" label="Pret minim" >
                                        <el-input v-model="Filters.PretMin" type="number" size="small"/>
                                    </el-form-item>
                                </el-col>

                                <el-col style="width:10%">
                                    <el-form-item label="Pret maxim">
                                        <el-input v-model="Filters.PretMax" type="number" size="small"/>
                                    </el-form-item>
                                </el-col>

                            </el-row>



                            <el-col>
                                <div class="footer-form">
                                    <el-button type="primary" native-type="submit" @click="refresh_info()" size="small">Aplica
                                    </el-button>
                                    <el-button type="danger" @click="reset()" size="small">Reseteaza</el-button>
                                    <el-button type="info" @click="print()" size="small">Printeaza</el-button>
                                </div>
                            </el-col>

                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>

        <el-table :header-cell-style="{ background: '#393E46' }" :data="Results" class="tabelRandMic">
            <el-table-column prop="CodProdus" label="Cod"></el-table-column>
            <el-table-column prop="Nume" label="Denumire"></el-table-column>
            <el-table-column align="right" prop="Pret" label="Pret"></el-table-column>
            <el-table-column align="right" prop="Stoc" label="Cantitate"></el-table-column>
            <el-table-column align="right"  label="Valoare">
            <template slot-scope="scope">
                {{ scope.row.Valoare | format_money }}
            </template>
            </el-table-column>
        </el-table>

    </base-page>
</template>
  
<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from "@/pages/clienti/Clienti_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
    name: 'app',
    extends: BasePage,
    components: {
        "base-page": BasePage,
        "Clienti-dialog": Clienti_dialog,
        "titlu-pagina": TitluPagina,
    },
    data() {
        return {
            Results: [],
            Filters: {
                IdGestiune: null,
                Categorie: "-1",
                PretMin: null,
                PretMax: null,
            },
            Info: {
                categorii: [],
            }

        }
    },
    methods: {
        async get_info() {
            var response = await this.post("Rapoarte/Stoc_marfa/get_info_marfa");
            this.Info.categorii = response.categorii;
            this.Info.Gestiuni = response.Gestiuni;
            if(this.Filters.IdGestiune == null) this.Filters.IdGestiune = this.Info.Gestiuni[0].Id;
            this.refresh_info();
        },
        async refresh_info() {
            var res = await this.post("Rapoarte/Stoc_marfa/get_raport_stoc_marfa", { Filters: this.Filters });
            this.Results = res.raspuns;
        },
        async reset() {
            this.Filters.IdGestiune = this.Info.Gestiuni[0].Id;
            this.Filters.Categorie = "-1";
            this.Filters.PretMin = null;
            this.Filters.PretMax = null;

            var res = await this.post("Rapoarte/Stoc_marfa/get_info_marfa", { Filters: this.Filters });
            this.refresh_info();
        },
        async print() {
            let res = await this.post('Rapoarte/Stoc_marfa/print_marfa', {Results: this.Results, Filters: this.Filters});
            let doc = window.open("", "_blank");
            doc.document.write(res.html);
        },
    },
    mounted: function () {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
}
</script>

<style lang="less" scoped>
.filtre {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(2);

    .el-col {
        padding: 0.25em;
    }

    .el-input-number {
        width: 100% !important;
    }

    .tabel-data {
        width: 300px !important;
        word-break: keep-all;

    }

}
</style>
